import React from "react";
import { UserContext } from "./providers/UserProvider.jsx";
import { withAlert } from "react-alert";
import { withRouter } from "react-router-dom";
import "./styles/styles.css";
import { Route, Switch, Redirect } from "react-router-dom";
import USUSgame from "./components/game_page/USUSgame";
import Leaderboard from "./components/leaderboard/Leaderboard";
import Admin from "./components/admin/Admin";
import Navbar from "./components/navbar/Navbar";
import NewsList from "./components/news/NewsList";
import SignIn from "./components/login/SignIn";
import RulesList from "./components/rules/RulesList";
import { auth, db } from "./services/firebase";
import { getLatestActiveNews } from "./helpers/news.js";
import { getAllRules } from "./helpers/rules";
import { library } from "@fortawesome/fontawesome-svg-core";
import { faDotCircle } from "@fortawesome/free-regular-svg-icons";
import {
  faTimes,
  faBars,
  faSadCry,
  faLaughBeam,
} from "@fortawesome/free-solid-svg-icons";

library.add(faDotCircle, faTimes, faBars, faSadCry, faLaughBeam);

class App extends React.Component {
  static contextType = UserContext;
  constructor(props) {
    super(props);
    this.state = {
      gameIsLive: true,
      showNews: true,
      gameIsComplete: false,
    };
    this.onSignInSuccess = this.onSignInSuccess.bind(this);
    this.handleSignOut = this.handleSignOut.bind(this);
    this.handleGameStateChange = this.handleGameStateChange.bind(this);
    this.handleError = this.handleError.bind(this);
    this.handleSuccess = this.handleSuccess.bind(this);
    this.handleSuccessfulCatch = this.handleSuccessfulCatch.bind(this);
    this.closeNews = this.closeNews.bind(this);
  }

  async componentDidMount() {
    this.setState({
      latestNews: await getLatestActiveNews(),
      allRules: await getAllRules("rules"),
    });

    //Lägg till listeners
    this.addGameStateListener();
    this.addPlayersStatusListener();
    this.addNewsListener();
  }

  onSignInSuccess = () => {
    this.setNumAliveNumCatched();
  };

  async setNumAliveNumCatched() {
    const ref = await db.collection("config").doc("data").get()
    this.setState({
      numAlive: ref.data().numAlive,
      numCatched: ref.data().numCatched
    });
  }

  handleSignOut = () => {
    auth.signOut();
    this.props.history.push("/");
  };

  handleGameStateChange() {
    this.setState((prevState) => ({
      gameIsLive: !prevState.gameIsLive,
    }));
  }

  addGameStateListener() {
    //Lyssnar på om spelet är live eller pausat
    db.collection("config")
      .doc("data")
      .onSnapshot((doc) => {
        this.setState({
          gameIsLive: doc.data().live,
        });
      });
    
    //Lyssnar på om det är 2 spelare kvar
    db.collection("config")
    .doc("data")
    .onSnapshot((doc) => {
      if (doc.data().numAlive === 2) {
        this.setState({
          gameIsComplete: true,
        });
      } else if (doc.data().numAlive > 2) {
        this.setState({
          gameIsComplete: false,
        })
      }
    });
  }

  addPlayersStatusListener() {
    //Lyssnar på om antalet levande och fångade spelare
    db.collection("config")
      .doc("data")
      .onSnapshot((doc) => {
        this.setState({
          numAlive: doc.data().numAlive,
          numCatched: doc.data().numCatched,
        });
      });
  }

  isActiveNews(news) {
    if (news.startDate.seconds < Date.now() / 1000 &&
      news.endDate.seconds > Date.now() / 1000) {
      return true;
    }
    else {
      return false;
    }
  }

  addNewsListener() {
    //Lyssnar på om det läggs till nyheter och om de är aktiva så sätts state
    db.collection("news").onSnapshot(
      { includeMetadataChanges: true },
      snapshot => {
        snapshot.docChanges().forEach(async change => {
          if (change.type === 'added') {
            if (this.isActiveNews(change.doc.data())) {
              this.setState({
                latestNews: change.doc.data(),
              });
            }
          }
        })
      }
    );
  }

  handleError(text) {
    this.props.alert.show(text, {
      type: "error",
    });
  }

  handleSuccess(text) {
    this.props.alert.show(text, {
      type: "success",
      timeOut: 1500,
    });
  }

  handleSuccessfulCatch() {
    this.props.alert.show("Spelare fångad!", {
      type: "success",
      timeout: 1500,
    });
  }

  closeNews() {
    this.setState({
      showNews: false,
    });
  }

  render() {
    const {
      latestNews,
      gameIsLive,
      numAlive,
      numCatched,
      allRules,
      showNews,
      gameIsComplete
    } = this.state;
    const user = this.context;

    return (
      <div className="App">
        <Navbar
          handleSignOut={this.handleSignOut}
          numAlive={numAlive}
          numCatched={numCatched} 
          />
        <Switch>
          <Route
            exact
            path="/"
            render={(props) => (
              <USUSgame
                news={latestNews}
                showNews={showNews}
                closeNews={this.closeNews}
                gameIsLive={gameIsLive}
                gameIsComplete={gameIsComplete}
                handleError={this.handleError}
                handleSuccessfulCatch={this.handleSuccessfulCatch}
              />
            )}
          >
            {!user && <Redirect to="/signin" />}
          </Route>
          <Route
            exact
            path="/signin"
            render={() => (
              <SignIn
                onSignInSuccess={this.onSignInSuccess}
                handleError={this.handleError}
              />
            )}
          >
            {user && <Redirect to="/" />}
          </Route>
          <Route
            exact
            path="/rules"
            render={() => <RulesList rules={allRules} />}
          />
          <Route
            exact
            path="/news"
            render={() => <NewsList handleError={this.handleError} />}
          />
          <Route
            exact
            path="/admin"
            render={() => (
              <Admin
                handleError={this.handleError}
                handleSuccess={this.handleSuccess}
                gameIsLive={gameIsLive}
                handleGameStateChange={this.handleGameStateChange}
              />
            )}
          >
            {user && !user.isAdmin && <Redirect to="/" />}
          </Route>
          <Route exact path="/leaderboard" render={() => <Leaderboard />} />
        </Switch>
      </div>
    );
  }
}

export default withRouter(withAlert()(App));
