import React from 'react';
import { UserContext } from "../../providers/UserProvider.jsx";
import { NavLink } from "react-router-dom";
import "./NavLinks.css";

const NavLinks = () => {
    return (
        <div className="NavLinks-container">
            <div className="NavLinks-wrapper">
                <NavLink
                    className="NavLinks-item"
                    to="/leaderboard"
                >
                    Leaderboard
                </NavLink>
                <NavLink
                    className="NavLinks-item"
                    to="/Rules"
                >
                    Regler
                </NavLink>
                <NavLink
                    className="NavLinks-item"
                    to="/news"
                >
                    Nyheter
                </NavLink>
                <UserContext.Consumer>
                    {(user) => user.isAdmin && (
                    <NavLink
                        className="NavLinks-item"
                        to="/admin"
                    >
                        Admin
                    </NavLink>
                    )}
                </UserContext.Consumer>
            </div>
        </div>
    );
}

export default NavLinks;