import React from 'react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "./Message.css";

const News = (props) => {

    if (!props.news) {
        return null;
    }

    return (
        <div className="Message-container">

            {props.length < 1 && <h1>Det finns inga nyheter just nu.</h1>}
            <div className="Message">
                <div className="Message-top-section">
                    <h5 className="News-title">{props.news.title}</h5>
                    {props.isCloseable && <div className="Message-close">
                        <FontAwesomeIcon className="close-icon" icon={["fas", "times"]} onClick={props.closeNews} />
                    </div>}      
                </div>
                <p className="News-text">{props.news.text}</p>
            </div>
        </div>
    );
}

export default News;