import React, { Component } from "react";
import Table from "react-bootstrap/Table";
import UserInTableAdmin from "./UserInTableAdmin";

export default class AdminUserList extends Component {
  render() {
    const { users } = this.props;

    var userList = [];
    if (users != null) {
      userList = []
        .concat(users)
        .map((user, index) => (
          <UserInTableAdmin key={user.id} user={user} index={index} updateUser={this.props.updateUser} />
        ));
    }

    return (
      <Table className="userTable">
        <thead>
          <tr>
            <th>Name</th>
            <th>Id</th>
            <th>CatchCode</th>
            <th>Catches</th>
            <th>Är fångad</th>
            <th>Ändra fångadstatus</th>
            <th>Target</th>
            <th>TargetAssignedTime</th>
          </tr>
        </thead>
        <tbody>{userList}</tbody>
      </Table>
    );
  }
}
