import React, { Component } from "react";
import "./Admin.css";
import NewsManager from "./NewsManager";
import UserManager from "./UserManager";
import RulesManager from "./RulesManager";
import GameManager from "./GameManager";
import { getData } from "../../helpers/users.js";
import {
  Route,
  NavLink,
  HashRouter
} from "react-router-dom";

/*
FUNKTIONALITET:

(X = finns)

X Kunna se alla användare (livstatus, antal fångster, target) = UserList
X Kunna lägga till en användare = skapa ett UserItem
- Kunna läsa från CSV och lägga till alla användare = skapa många UserItem
X Kunna ta bort en användare = Ta bort ett UserItem
X Kunna göra en fångst mellan två användare 
- Se catchevents-log som pushas automatiskt
- Kunna döda alla
- Kunna pausa spelet (ingen kan fånga eller fångas) /fryser UI:t på USUSGame
- Kunna nollställa/reseta spelet(?)
- Kunna skriva ett meddelande som visas för alla användare /USUSGame ska kolla efter meddelande



*/

class Admin extends Component {
  constructor(props) {
    super(props);
    this.state = {
      users: null,
      toggleTable: true,
    };

    this.handleToggleTable = this.handleToggleTable.bind(this);
  }
  async componentDidMount() {
    this.setState({
      users: await getData("users"),
    });
  }
  handleToggleTable() {
    this.setState((prevState) => ({
      toggleTable: !prevState.toggleTable,
    }));
  }

  render() {
    const { gameIsLive, handleGameStateChange } = this.props;
    return (

      <HashRouter>
        <div className="main">
          <div className="header">
            <NavLink activeClassName="activeLink" exact to="/"><button className="headerButton">Användare</button></NavLink>
            <NavLink activeClassName="activeLink" to="/newsmanager"><button className="headerButton">Nyheter</button></NavLink>
            <NavLink activeClassName="activeLink" to="/rulesmanager"><button className="headerButton">Regler</button></NavLink>
            <NavLink activeClassName="activeLink" to="/gamemanager"><button className="headerButton">Inställningar</button></NavLink>
          </div>
          <div className="content">
            <Route exact path="/" render={(props) =>
              <UserManager
                users={this.state.users}
                handleError={this.props.handleError}
                handleSuccess={this.props.handleSuccess}
              />} />
            <Route path="/newsmanager" render={() =>
              <NewsManager
                handleError={this.props.handleError}
                handleSuccess={this.props.handleSuccess}
              />} />
            <Route path="/rulesmanager" render={() =>
              <RulesManager
                handleError={this.props.handleError}
                handleSuccess={this.props.handleSuccess}
              />} />
            <Route path="/gamemanager" render={() =>
              <GameManager
                gameIsLive={gameIsLive}
                handleGameStateChange={handleGameStateChange}
                handleError={this.props.handleError}
                handleSuccess={this.props.handleSuccess}
              />} />
          </div>
        </div>
      </HashRouter>








    );
  }
}

export default Admin;

/*
<AdminUserList users={this.props.users} />
        <AdminCatchList />




        <NewsManager />

        <UserManager/>

        <GameManager/>*/