import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import { transitions, positions, Provider as AlertProvider } from 'react-alert';
import AlertTemplate from 'react-alert-template-basic';
import UserProvider from "./providers/UserProvider.jsx";
import "./styles/styles.css";
import App from "./App";
import "bootstrap/dist/css/bootstrap.css";

const options = {
  position: positions.BOTTOM_CENTER,
  timeout: 5000,
  offset: '30px',
  transition: transitions.SCALE,
}

ReactDOM.render(
  <BrowserRouter>
    <React.StrictMode>
      <UserProvider>
      <AlertProvider template={AlertTemplate} {...options}>
        <App />
      </AlertProvider>
      </UserProvider>
    </React.StrictMode>
  </BrowserRouter>,
  document.getElementById("root")
);
