import React, { Component } from "react";
import { GetLeaderboard } from "../../helpers/game.js";
import UserInTable from "./UserInTable";
import Loader from './Loader';
import "./Leaderboard.css";

class Leaderboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      users: null,
      isLoading: true,
    };
  }

  async componentDidMount() {
    var userList = await GetLeaderboard();
    this.setState({
      users: userList.data,
    }, () => {
      this.setState({
        isLoading: false,
      });
    });
  }

  render() {
    const { users, isLoading } = this.state;
    var usersList = [];
    if (users != null) {
      usersList = users
        .sort((a, b) => (a.catches > b.catches ? -1 : 1))
        .map((user, index) => (
          <UserInTable key={index} user={user} index={index} />
        ));
    }
    return (
      <div className="Leaderboard">
        {isLoading ? <Loader />
       : 
      <div className="Leaderboard-box">
        <table className="Leaderboard-table">
          <thead className="Leaderboard-head">
            <tr className="Leaderboard-table-row">
              <th>Position</th>
              <th>Name</th>
              <th>Catches</th>
            </tr>
          </thead>
          <tbody>{usersList}</tbody>
        </table>
      </div>
      }
      </div>
    )
  }
}


export default Leaderboard;

