import React, { useState } from 'react';
import { useMediaQuery } from 'react-responsive';
import { NavLink } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import NavLinks from "./NavLinks";
import MobileNavLinks from './MobileNavLinks';
import { UserContext } from "../../providers/UserProvider.jsx";
import "./Navbar.css";
import usLogo from "../../assets/logo-square.png";

const NavBar = ({ handleSignOut, numAlive, numCatched }) => {
    const isMobile = useMediaQuery({ maxWidth: 918 });
    const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);

    return (
        <div className="NavBarContainer">
            <NavLink className="Navbar-left-section" to="/" onClick={() => setIsMobileMenuOpen(false)}>
                <div className="logo-wrapper">
                    <img className="logo-img" src={usLogo} alt="" />
                    <div className="logo-text">Catch<span>game</span></div>
                    <UserContext.Consumer>
                        {(user) => user && (
                            <div className="Navbar-alive-catched-wrapper">
                                <div className="Navbar-alive">
                                    <FontAwesomeIcon icon={"laugh-beam"} />:{numAlive}
                                </div>
                                <div className="Navbar-catched">
                                    <FontAwesomeIcon icon={"sad-cry"} />:{numCatched}
                                </div>
                            </div>
                        )}
                    </UserContext.Consumer>
                </div>
            </NavLink>
            <UserContext.Consumer>
                {(user) => user && (
                    <div className="Navbar-middle-section">
                        {!isMobile && <NavLinks />}
                    </div>
                )}
            </UserContext.Consumer>
            <UserContext.Consumer>
                {(user) => user && (
                    <div className="navbar-right-section">
                        {!isMobile && <button onClick={handleSignOut} className="Navbar-logout-button">Logga ut</button>}
                        {isMobile && <MobileNavLinks isMobileMenuOpen={isMobileMenuOpen} toggle={() => setIsMobileMenuOpen(!isMobileMenuOpen)} handleSignOut={handleSignOut} />}
                    </div>
                )}
            </UserContext.Consumer>

        </div>
    );
}

export default NavBar;