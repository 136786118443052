import React from "react";

class UserInTable extends React.Component {
  render() {
    const { user, catches } = this.props.user;
    const { index } = this.props;
    return (
      <tr className="Leaderboard-user-row">
        <td>{index + 1}</td>
        <td>{user}</td>
        <td>{catches}</td>
      </tr>
    );
  }
}
export default UserInTable;
