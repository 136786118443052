import React, { Component } from "react";
import { createRules } from "../../helpers/rules";

class RulesManager extends Component {
    constructor(props) {
        super(props);
        this.state = {
          rulesTitle: "",
          rulesText: "",
        };
        this.handleChange = this.handleChange.bind(this);
        this.handleAddRules = this.handleAddRules.bind(this);
    }
      handleChange(e) {
        this.setState({
          [e.target.name]: e.target.value,
        });
      }
      async handleAddRules(e) {
        e.preventDefault();
        const status = await createRules(this.state.rulesTitle, this.state.rulesText);
        if (status.error) {
          this.props.handleError("Något gick fel...");
        } else if (!status.error) {
          this.props.handleSuccess("Regel skapad");
        }
      }
      render() {
        return (
        <div className="rulesManager">
            <h2>Lägg till regel</h2>
            <form className="addRules" onSubmit={this.handleAddRules}>
                <input
                    required
                    className="addRules-input"
                    type="Title"
                    placeholder="Titel..."
                    name="rulesTitle"
                    value={this.state.rulesTitle}
                    onChange={this.handleChange}
                />
                <textarea
                    required
                    className="addRules-input"
                    rows="5"
                    placeholder="Text..."
                    name="rulesText"
                    value={this.state.rulesText}
                    onChange={this.handleChange}
                />
                <button className="addRules-submit" type="submit">
                    Lägg till regel
                </button>
            </form>
         </div>
        );
      }
}
export default RulesManager;