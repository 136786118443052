import React, { useState, useEffect} from 'react';
import { getAllActiveNews } from '../../helpers/news';
import News from "./News";
import Loader from './Loader';
import "./NoNews.css";
import "./NewsList.css";

const NewsList = ({handleError}) => {
    const [news, setNews] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [hasError, setHasError] = useState(false);

    useEffect(() => {
        fetchData();
    }, [])

    async function fetchData() {
        const activeNews = await getAllActiveNews()
        try {
            if (activeNews.length < 1) {
                setNews([{title: "Inga nyheter att visa...", text: ""}])
            } else {
                setNews(activeNews);
            }
        } catch (e) {
            handleError("Något gick fel när nyheter hämtades")
            setHasError(true);
        } finally {
            setIsLoading(false);
        }
    }

    if (hasError) return null;
    return(
        <div className="NewsList">
            {isLoading ? <Loader /> : news.map(news => <News key={news.title} isCloseable={false} news={news} /> )}
        </div>
    );
}
export default NewsList;