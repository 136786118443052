import React, { Component } from "react";
import { auth } from "../../services/firebase";
import "./LoginForm.css";
import loginCall from "../../helpers/loginAPI";
import { createUser, userHasCatchCode, userExists } from "../../helpers/users";
import Loader from "./Loader";

class LoginForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      password: "",
      email: "",
      showLoader: false,
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleChange(e) {
    this.setState({
      [e.target.name]: e.target.value,
    });
  }

  async handleSubmit(e) {
    e.preventDefault();
    this.setState({
      showLoader: true,
    });
    let result = await loginCall(this.state.email, this.state.password);
    if (result.success) {
      const exists = await userExists(result.user_id);
      if (exists) {
        const hasCatchCode = await userHasCatchCode(result.user_id);
        //Om användaren redan har en catchcode -> logga in som vanligt
        if (hasCatchCode)
        {
          await auth
          .signInWithCustomToken(result.token)
          .catch((err) => console.log(err.message));
          this.props.onSignInSuccess();   
        } 
        //Om användaren inte har en catchcode sen tidigare -> skapa en ny användare och logga sedan in
        else if (!hasCatchCode) {
          await createUser(result.user_id, result.user_name);
          await auth
          .signInWithCustomToken(result.token)
          .catch((err) => console.log(err.message));
          this.props.onSignInSuccess(); 
        }  
      }
      else {
        this.props.handleError("Du finns inte med som en registrerad spelare. Kontakta eventutskottet ifall du har frågor.");
        this.setState({
          showLoader: false,
        });
      }
    } else {
      this.props.handleError(result.errorText);
      this.setState({
        showLoader: false,
      });
    }
  }

  render() {
    const { showLoader } = this.state;
    return (
      <div className="LoginForm">
        <form className="LoginForm-form" onSubmit={this.handleSubmit}>
          <input
            className="LoginForm-form-input"
            type="email"
            placeholder="Email"
            name="email"
            onChange={this.handleChange}
          />
          <input
            className="LoginForm-form-input"
            type="password"
            placeholder="Password"
            name="password"
            onChange={this.handleChange}
          />
          <button className="LoginForm-form-submit" type="submit">
          {showLoader ? <Loader /> : "Login"}
          </button>
        </form>
      </div>
    );
  }
}

export default LoginForm;
