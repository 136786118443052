import { db, functions } from "../services/firebase";

export async function GetLeaderboard() {
  var returnValue = functions.httpsCallable("GetLeaderboards");
  var userList = await returnValue();
  return userList;
}

//Denna funktion tar in userID från den som genomfört en fångst och den catchCode som skrivits in i rutan.
export async function catchAttempt(userID, CatchCode) {
  //Gör om catchcode till upper case för att förhindra case sensitivity vid inmatning
  var upperCatchCode = CatchCode.toUpperCase();

  var result = await validateCatch(userID, upperCatchCode);
  return result;
}

//Kallar på cloud function för att validera huruvida man försöker fånga ens tilldelade target
//Returnerar true / false
async function validateCatch(userID, CatchCode) {
  var ValidateCatch = functions.httpsCallable("ValidateCatch");
  return ValidateCatch({ userID: userID, catchCode: CatchCode })
    .then(function (result) {
      var validation = result.data.return;
      return validation;
    })
    .catch(function (error,result) {
      var code = error.code;
      var message = error.message;
      var details = error.details;
      console.log(code + " " + message + " " + details);
      return {
        return: false
      };
    });
}

export function getTimeStamp() {
  const time = Date.now();
  let timestamp = new Intl.DateTimeFormat("sv-SV", {
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
    hour: "2-digit",
    minute: "2-digit",
  }).format(time);

  return timestamp;
}

export async function getGameState() {
  const gameStateRef = db.collection("config").doc("data").get();
  const gameState = gameStateRef.then((doc) => doc.data().live);
  return gameState;
}
