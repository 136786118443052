import React from "react";
import { createContext } from "react";
import { getUser } from "../helpers/users";
import { auth, db } from "../services/firebase";

export const UserContext = createContext({ user: null });

class UserProvider extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      user: null,
    };
  }

  componentDidMount = async () => {
    try {
      auth.onAuthStateChanged(async (userAuth) => {
        if (userAuth) {
          const user = await getUser(userAuth.uid);
          this.setState({ user });
          this.addUserChangeListener(userAuth.uid);
          } else {
          this.setState({
            user: null,
          });
        }
      });
    } catch ({error}) {
      alert("Något oväntat gick fel, testa att refresha sidan...");
      console.log(error);
    } 
  };

  addUserChangeListener(uid) {
    db.collection("users")
      .doc(uid)
      .onSnapshot(async (doc) => {
        this.setState({
          user: {uid, ...doc.data()},
        });
      });
  }

  render() {
    return (
      <UserContext.Provider value={this.state.user}>
        {this.props.children}
      </UserContext.Provider>
    );
  }
}

export default UserProvider;
