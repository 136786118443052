import React, { Component } from "react";
import {
  changeGameState,
  targetGenerator,
  resetAllUsers,
  clearDb,
} from "../../helpers/admin";
import "./Admin.css";

class GameManager extends Component {

  constructor(props) {
    super(props)
    this.handleGameStateChange = this.handleGameStateChange.bind(this);
    this.handleClearClick = this.handleClearClick.bind(this);
  }

  async handleGameStateChange() {
    await changeGameState();
  }

  handleClearClick() {
    const confirmed = window.confirm("Nollställ hela databasen?");
    if (confirmed) {
      clearDb();
    }
  }

  handleResetClick() {
    const confirmed = window.confirm("Vill du återställa spelet så att alla användare blir återupplivade och fångster nollställs?");
    if (confirmed) {
      resetAllUsers();
    }
  }

  handleStartClick() {
    const confirmed = window.confirm("Vill du göra så att alla användare som är kvar får en ny person att fånga?");
    if (confirmed) {
      targetGenerator();
    }
  }

  async handlePauseClick() {
    const confirmed = window.confirm(`${this.props.gameIsLive ? "Vill du pausa spelet?" : "Vill du återupta spelet"}`);
    if (confirmed) {
      await changeGameState();
    }
  }

  render() {
    const { gameIsLive } = this.props;
    return (
      <div className="gameFunctions">
        <h2>Spelfunktioner</h2>
        <div className="button-array">
          <button className="function-button" onClick={() => this.handleStartClick()}>Generera nya mål</button>
          <button className={`${gameIsLive ? "function-button" : "function-button function-button-paused"}`} onClick={() => this.handlePauseClick()}>{`${gameIsLive ? "Pausa spelet" : "Återupta spelet"}`} </button>
          <button className="function-button" onClick={() => this.handleResetClick()}>Återuppliva alla och nollställ fångster</button>
          <button className="function-button" onClick={() => this.handleClearClick()}>Rensa databasen</button>
        </div>
        <div className="listCatchEvent">{/* Lista catchevents */}</div>
      </div>
    )
  }
}

export default GameManager;