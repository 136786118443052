/* Skickar en post request till uppsala systemvetares api för login */
async function loginCall(email, password) {
  let URL = "https://api.uppsalasystemvetare.se/api/loginFirebase.php";
  try {
    const response = await fetch(URL, {
      method: "POST",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
      body: `email=${email}&password=${password}`,
    });
    if (!response.ok) {
      return {
        success: false,
        errorText: "Lösenord eller email stämmer inte"
      };
    }
    if (response.ok) {
      let user = await response.json();
      return {
        token: user.access_token,
        success: true,
        user_id: user.user_id,
        user_name: user.user_firstName + " " + user.user_lastName,
      };
    }
  } catch (error) {
    return {
      success: false,
      error,
    };
  }
}

export default loginCall;
