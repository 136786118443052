import React from 'react';
import "./Rule.css";
const Rule = (props) => {
    if (!props.rule) {
        return null;
    }
    return (
        <div className="Rule-container">
            <div className="Rule">
                <div className="Rule-title">
                    {props.rule.title}
                </div>
                <div className="Rule-text">
                    {props.rule.text}
                </div>
            </div>
        </div>
    );
}

export default Rule;