import React, { Component } from "react";
import { AnimatePresence, motion } from 'framer-motion';
import CatchForm from "./CatchForm";
import UserBox from "./UserBox";
import News from "../news/News";
import "./Loader.css";
import "./USUSgame.css";



class USUSgame extends Component {
  async onComponentDidMount() {
    this.setState({
      displayNews: true,
    });
  }
  
  closeNews() {
    this.setState({
      displayNews: false
    });
  }
  render() {
    const { news, gameIsLive, handleError, handleSuccessfulCatch, closeNews, showNews, gameIsComplete } = this.props;
    return (
      <div className="USUSgame">
        <AnimatePresence>
          {(showNews && news) && (
            <motion.div
              animate={{ height: "180px" }}
              initial={{ height: 0 }}
              exit={{ height: 0, opacity: 0 }}
            >
              <News news={news} isCloseable={true} closeNews={closeNews} />
            </motion.div>
          )}
        </AnimatePresence>
        {(gameIsLive && !gameIsComplete) && <CatchForm handleError={handleError} handleSuccessfulCatch={handleSuccessfulCatch} />}
        {(gameIsLive && !gameIsComplete) && <UserBox />}
        {!gameIsLive && <h1 className="pause-text">Spelet är pausat...</h1>}
        {gameIsComplete && <h1 className="pause-text">Spelet är avgjort!!!!</h1>}
      </div>
    );
  }
}

export default USUSgame;
