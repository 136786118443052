import React from 'react';
import Rule from "./Rule";


const RulesList = (props) => {
    if (!props.rules)
    {
        return null;
    }
    const rulesList = props.rules.map(rule => <Rule key={rule.title} rule={rule} />);
    return (
        <div className="RulesList">
            {rulesList}
        </div>
    );
}

export default RulesList;