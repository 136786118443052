import { getTimeStamp } from "../helpers/game.js";
import { createUser, updateUser, getData } from "./users";
import { db } from "../services/firebase";
import { firestore } from "firebase";

export async function reviveAll() {
  let data = await getData("users");
  data.forEach((user) => {
    updateUser("users", user.id, "isAlive", true);
    db.collection("config")
      .doc("data")
      .update({ numAlive: firestore.FieldValue.increment(1) });
  });
  console.log("Sätter alla isAlive : true...");
}

export async function setAllTargetsToNull() {
  let data = await getData("users");
  data.forEach((user) => {
    updateUser("users", user.id, "target", null);
  });
  console.log("Sätter alla target : null...");
}

export async function zeroAllCatches() {
  let data = await getData("users");
  data.forEach((user) => {
    updateUser("users", user.id, "catches", 0);
  });
  console.log("Sätter alla catches till 0 ...");
}

export async function giveAllUsersCatchCode() {
  let data = await getData("users");
  data.forEach(async (user) => {
    let CatchCode = await makeid(4);
    updateUser("users", user.id, "catchCode", CatchCode);
  });
  console.log("Ger alla ett CatchCode");
}

export async function clearAllCatchLists() {
  let data = await getData("users");
  let empty = [];
  data.forEach((user) => {
    updateUser("users", user.id, "catchList", empty);
  });
  console.log("Tömmer alla catchList...");
}

export async function targetGenerator() {
  let data = await getData("users");
  let userAlive = data.filter((alive) => alive.isAlive === true);
  let users = userAlive.map((user) => ({id: user.id, name: user.name }));
  let usersShuffeled = shuffle(users);
  let index = 0;
  let timestamp = getTimeStamp();
  usersShuffeled.forEach(user => {
    if (index === usersShuffeled.length - 1) {
      db.collection("users").doc(user.id).update({
        target: usersShuffeled[0].id,
        targetName: usersShuffeled[0].name,
      });
      index = 0;
    } else {
      db.collection("users")
        .doc(user.id)
        .update({
          target: usersShuffeled[index + 1].id,
          targetName: usersShuffeled[index + 1].name,
          targetAssigned: timestamp,
        });
      index = index + 1;
    }
  });
}

//Slumpar ordningen i en array
function shuffle(array) {
  var currentIndex = array.length,
    temporaryValue,
    randomIndex;

  // While there remain elements to shuffle...
  while (0 !== currentIndex) {
    // Pick a remaining element...
    randomIndex = Math.floor(Math.random() * currentIndex);
    currentIndex -= 1;

    // And swap it with the current element.
    temporaryValue = array[currentIndex];
    array[currentIndex] = array[randomIndex];
    array[randomIndex] = temporaryValue;
  }
  return array;
}

export async function makeid(length) {
  var characters = "ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789";
  var charactersLength = characters.length;
  var result = "";
  for (var i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
}

export async function deleteAllUsers() {
  await db
    .collection("users")
    .get()
    .then((user) => {
      user.forEach((element) => {
        element.ref.delete();
      });
    });
}

export async function resetAllUsers() {
  db.collection("config").doc("data").update({ numAlive: 0 });
  db.collection("config").doc("data").update({ numCatched: 0 });
  reviveAll();
  setAllTargetsToNull();
  clearAllCatchLists();
  zeroAllCatches();
  giveAllUsersCatchCode();
}
export async function populateDefaultDb() {
  await createUser("a", "Kalle");
  await createUser("b", "Knatte");
  await createUser("c", "Fnatte");
  await createUser("d", "Tjatte");
  await createUser("e", "Joakim");
  await createUser("f", "Magica");
  await createUser("g", "Mårten");
  await createUser("h", "Guld-Ivar");
  await createUser("i", "Kajsa");
  await createUser("j", "Alexander Lukas");
}
export async function resetDb() {
  await deleteAllUsers();
  await populateDefaultDb();
  targetGenerator();
}

export function clearDb() {
  deleteAllUsers();
  deleteCatchEvents();
}

export async function deleteCatchEvents() {
  await db
    .collection("catchEvent")
    .get()
    .then((event) => {
      event.forEach((element) => {
        element.ref.delete();
      });
    });
}

//Function som "flippar" game state. Transactions används för att säkerställa att olika användare inte overwritar varandras ändringar.
export async function changeGameState() {
  const gameStateRef = await db.collection("config").doc("data");
  return db
    .runTransaction(function (transaction) {
      return transaction.get(gameStateRef).then(function (doc) {
        transaction.update(gameStateRef, { live: !doc.data().live });
      });
    })
    .then(function () {
      console.log("Transaction successfully comitted!");
    })
    .catch(function (error) {
      console.log("Transaction failed: ", error);
    });
}
