import { db } from "../services/firebase";

export async function createRules(title, text) {
  const status = await db.collection("rules")
        .doc()
        .set({
            title: title,
            text: text,
        })
        .then(function () {
            return {
                error: false
            }
        })
        .catch(function (error) {
            return {
                error: true
            }
        });
    return status;
}

export async function getAllRules() {
    try {
        const rulesDocuments = await db.collection("rules").get();
        const rulesData = rulesDocuments.docs.map((doc) => doc.data());
        return rulesData;
    } catch (e) {
        console.log(e.message);
    }
}