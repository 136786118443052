import { db } from "../services/firebase";
//Funktion som returnerar alla aktiva nyheter, nyast först.
export async function getAllActiveNews() {
    try {
        const newsDocuments = await db.collection("news").get();
        const newsData = newsDocuments.docs.map((doc) => doc.data());
        const activeNews = newsData.filter(
            (data) =>
                data.startDate.seconds < Date.now() / 1000 &&
                data.endDate.seconds > Date.now() / 1000
        );

        const sortedNews = activeNews.sort((a, b) => {
            if (a.startDate.seconds > b.startDate.seconds) {
                return -1;
            }
            if (a.startDate.seconds < b.startDate.seconds) {
                return 1;
            }
            else return 0;
        });
        return sortedNews;
    } catch (e) {
        if (!e.message.includes("Cannot read property")) {
            console.log(e.message);
        }
    }
}
//Funktion som returnerar den senaste aktiva nyheten.
export async function getLatestActiveNews() {
    try {
        const news = await getAllActiveNews();
        const latestNews = { title: news[0].title, text: news[0].text };
        return { title: latestNews.title, text: latestNews.text };
    } catch (e) {
        if (!e.message.includes("Cannot read property")) {
            console.log(e.message);
        }
    }
}

export async function createNews(title, text, startDate, endDate) {
    const status = await db.collection("news")
        .doc()
        .set({
            title: title,
            text: text,
            startDate: startDate,
            endDate: endDate,
        })
        .then(function () {
            return {
                error: false,
            }
        })
        .catch(function (error) {
            return {
                error: true,
            }
        });
    return status;
}