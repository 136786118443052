import React, { Component } from "react";
import { AnimatePresence, motion } from 'framer-motion';
import { UserContext } from "../../providers/UserProvider.jsx";
import { catchAttempt } from "../../helpers/game.js";
import QRread from "../../helpers/QRCode.js";
import Loader from "./Loader";
import "./CatchForm.css";

class CatchForm extends Component {
  static contextType = UserContext;
  constructor(props) {
    super(props);
    this.state = {
      inputTargetId: "",
      targetName: "",
      showQRReader: false,
      showCatches: false,
      showLoader: false,
    };
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleQRButton = this.handleQRButton.bind(this);
    this.handleQRRead = this.handleQRRead.bind(this);
  }

  showError() {
    this.props.handleError("Du har angett fel kod.");
  }

  async handleSubmit(e) {
    this.setState({
      showLoader: true,
    });
    e.preventDefault();
    //The catchcode being submitted
    const CatchCode = this.state.inputTargetId.toString();
    //If empty, hide loader and show error.
    if (CatchCode === "") {
      this.setState({
        showLoader: false,
      });
      this.showError();
    } else {
      //Try to catch with the provided code
      let success = await catchAttempt(this.context.uid, CatchCode);
      //If catch attempt was successful, handle the catch and hide loader else show error and hide loader.
      if (success) {
        this.props.handleSuccessfulCatch();
        this.setState({
          showLoader: false,
        });
      } else {
        this.setState({
          showLoader: false,
        });
        this.showError();
      }
      //Reset the form input
      this.setState({
        inputTargetId: "",
      });
    }
  }

  handleQRButton() {
    this.setState((prevState) => ({
      showQRReader: !prevState.showQRReader,
    }));
  }

  handleQRRead(result, tof) {
    if (tof) {
      this.setState({
        inputTargetId: result,
      });
    }
  }

  handleChange(e) {
    this.setState({
      [e.target.name]: e.target.value,
    });
  }
  handleInputChange(event) {
    this.setState({
      targetId: event.target.value,
    });
  }

  render() {
    const { showQRReader, showLoader } = this.state;
    if (!this.context.isAlive) {
      return (
        <div className="CatchForm">
          <h4 className="CatchForm-catched">
            Du är fångad, spelet är slut för denna gång...
          </h4>
        </div>
      );
    }
    return (
      <div className="CatchForm">
        <div className="CatchForm-target">
          <p>Du ska fånga:</p>
          <h4>{this.context.targetName}</h4>
        </div>

        <form className="CatchForm-form" onSubmit={this.handleSubmit}>
          <input
            className="CatchForm-form-input"
            type="text"
            placeholder="Fiendekod"
            name="inputTargetId"
            onChange={this.handleChange}
            value={this.state.inputTargetId}
          />
          <AnimatePresence>
            {this.state.showQRReader && (
              <motion.div
                animate={{ height: "260px" }}
                initial={{ height: 0 }}
                exit={{ height:0, opacity: 0}}
                className="CatchForm-qr-wrapper">
                <QRread handleQRRead={this.handleQRRead} />
              </motion.div>
            )}
          </AnimatePresence>
          <button className="CatchForm-form-submit" type="submit">
            {showLoader ? <Loader /> : "Fånga!"}
          </button>
          <button
            className="CatchForm-form-qr-button"
            onClick={this.handleQRButton}
            type="button"
          >
            {showQRReader ? "Dölj QR-läsare" : "Visa QR-läsare"}
          </button>
        </form>
      </div>
    );
  }
}

export default CatchForm;
