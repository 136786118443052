import firebase from 'firebase/app';

import "firebase/auth";
import "firebase/database";
import "firebase/firestore";
import "firebase/functions";

const firebaseConfig = {
  apiKey: "AIzaSyA8G1XltRbIYwu7n37uc4ZSppD-gPgKbDM",
  authDomain: "sysvetkillergame.firebaseapp.com",
  databaseURL: "https://sysvetkillergame.firebaseio.com",
  projectId: "sysvetkillergame",
  storageBucket: "sysvetkillergame.appspot.com",
  messagingSenderId: "30356276240",
  appId: "1:30356276240:web:0210a5cf8e07ab7e2a3695",
};

firebase.initializeApp(firebaseConfig);
//auth kommer användas i production när databasen kommer låsas.
export const auth = firebase.auth();

export const db = firebase.firestore();
export const functions = firebase.app().functions("europe-west1");