import React, { Component } from "react";
import FileReader from './FileReader.js'
import AdminUserList from "./AdminUserList";

import {
    createUser,
    removeUser,
    updateUser
  } from "../../helpers/users";

import { catchAttempt } from "../../helpers/game";


class UserManager extends Component{

    constructor(props) {
        super(props);
        this.state = {
          addUserName: "",
          addUserId: "",
          removeUserId: "",
          searchUserId: "",
          catchId: "",
          tempCatchCode: "",
          tempUserId: "",
          newsHeader: "",
          newsMessage: "",
          toggleTable: true,
        };

    this.handleChange = this.handleChange.bind(this);
    this.handleAddUser = this.handleAddUser.bind(this);
    this.handleRemoveUser = this.handleRemoveUser.bind(this);
    this.handleCatch = this.handleCatch.bind(this);

    };

    handleChange(e) {
        this.setState({
          [e.target.name]: e.target.value,
        });
      }

      async handleAddUser(e) {
        e.preventDefault();
        const status = await createUser(this.state.addUserId, this.state.addUserName);
        if (status.error) {
          this.props.handleError("Något gick fel...");
        } else if (!status.error) {
          this.props.handleSuccess("Användare skapad");
        }
      }

      async handleRemoveUser(e) {
        e.preventDefault();
        const status = await removeUser(this.state.removeUserId);
        console.log(status);
        if (status.error) {
          this.props.handleError("Något gick fel...");
        } else if (!status.error) {
          this.props.handleSuccess("Användare borttagen");
        }
      }

     async handleCatch(e) {
        e.preventDefault();
        const status = await catchAttempt(
          this.state.tempUserId.toString(),
          this.state.tempCatchCode.toString()
        );
        if (status.return) {
          this.props.handleError("Något gick fel...");
        } else if (!status.return) {
          this.props.handleSuccess("Användare fångad!");
        }
      }

    render(){
        return(
          <div className="userManagerWrapper">
            <div className = "userTableWrapper">
              <AdminUserList users={this.props.users} updateUser={updateUser} />  
            </div>
          <FileReader></FileReader>


            <div className="misc">

             
            <div className="addUser">
              <div className = "rubrik">
                <h6>Lägg till:</h6>
              </div>
            <form className="Test-adduser" onSubmit={this.handleAddUser}>
              
              <input
                required
                className="user-form-input"
                type="text"
                placeholder="Namn..."
                name="addUserName"
                value={this.state.addUserName}
                onChange={this.handleChange}
              />

              <input
                required
                className="user-form-input"
                type="text"
                placeholder="userId"
                name="addUserId"
                value={this.state.addUserId}
                onChange={this.handleChange}
              />

              <button className="user-table-button" type="submit">
                Lägg till användare
              </button>

            </form>

          </div>


          <div className="removeUser">
            <div className = "rubrik">
              <h6>Ta bort:</h6>
            </div>
            {/* Kunna ta bort enskild användare*/}
            <form className="removeUser" onSubmit={this.handleRemoveUser}>
              <input
                required
                className="user-form-input"
                type="text"
                placeholder="userId"
                name="removeUserId"
                value={this.state.removeUserId}
                onChange={this.handleChange}
              />
              <button className="user-table-button" type="submit">
                Ta bort användare
              </button>
            </form>
          </div>

          <div className="catchForm">
            <div className = "rubrik">
              <h6>Fånga:</h6>
            </div>
            <form className="Test-catch-form2" onSubmit={this.handleCatch}>
              <input
                required
                className="user-form-input"
                type="text"
                placeholder="Användar id på den som ska fånga..."
                name="tempUserId"
                value={this.state.tempUserId}
                onChange={this.handleChange}
              ></input>

              <input
                required
                className="user-form-input"
                type="text"
                placeholder="catchCode på den som ska bli fångad..."
                name="tempCatchCode"
                value={this.state.tempCatchCode}
                onChange={this.handleChange}
              ></input>

              <button className="user-table-button" type="submit">Fånga!</button>
            </form>
            </div>
            </div>
           

        </div>
        )
    }    

}



export default UserManager;