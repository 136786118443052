import React from "react";



class UserInTableAdmin extends React.Component {
  render() {
    const {
      name,
      catches,
      id,
      isAlive,
      catchCode,
      target,
      targetAssigned,
    } = this.props.user;

    const handleToggle = () => {
      this.props.updateUser("users",id,"isAlive", !isAlive)
      alert("Uppdatera sidan för att se ändringen!")
    }

    let lever = !isAlive ? "Ja" : "Nej";

    //const { index } = this.props;
    return (
      <tr>
        <td>{name}</td>
        <td>{id}</td>
        <td>{catchCode}</td>
        <td>{catches}</td>
        <td>{lever}</td>
        <td><button className="user-table-button" onClick={handleToggle}>Växla</button></td>
        <td>{target}</td>
        <td>{targetAssigned}</td>
        
      </tr>
    );
  }
}
export default UserInTableAdmin;
