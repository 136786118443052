import React, { Component } from "react";
import QrReader from "react-qr-reader";

class QRread extends Component {
  constructor(props) {
    super(props);
    this.state = {
      result: "No result",
      trueOrFalse: false,
    };
    this.handleScan = this.handleScan.bind(this);
  }
  async componentDidMount() {}

  handleScan = (data) => {
    console.log(data);
    if (data) {
      this.setState({
        result: data,
        trueOrFalse: true,
      });
      this.props.handleQRRead(data, this.state.trueOrFalse);
    }
  };
  handelError = (err) => {
    console.error(err);
  };
  render() {
    return (
      <div>
        <QrReader
          delay={300}
          onError={this.handelError}
          onScan={this.handleScan}
          style={{ width: "100%"}}
        />
      </div>
    );
  }
}
export default QRread;
