import React, { useContext } from "react";
import { UserContext } from "../../providers/UserProvider.jsx";
import QRCode from "qrcode.react";
import "./UserBox.css";

const UserBox = ({catchCode}) => {
  const user = useContext(UserContext);
  return (
    <div className="UserBox-container">
      <div className="UserBox">
          <div className="UserBox-catchCode-container">
            <p>Min kod:</p>
            <h4>{user.catchCode}</h4>
          </div>
          <p className="UserBox-QR-container">
            <QRCode value={user.catchCode} />
          </p>
      </div>
    </div>
  );
};

export default UserBox;
