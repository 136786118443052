//I denna mapp ligger funktioner gällande users.
import { db, functions } from "../services/firebase";
import { makeid } from "./admin.js";

/*Funktion som tar in namnet på en datasamling (ex 'users' -> [{username: "Christoffer" (...)}, 
  {username: "Douglas", (...)}]), och returnerar en array med alla objekt */
export async function getData(collection) {
  const data = await db
    .collection(collection)
    .get()
    .then((querySnapshot) => {
      const tempDoc = querySnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      return tempDoc;
    });
  return data;
}

export async function compare(collection, key, value) {
  getUser(collection, key, value).then((result) => {
    if (result.length === 1) {
      return Boolean(true);
    } else {
      return Boolean(false);
    }
  });
}
/* Skapar en användare i databasen, tar in namnet på en användare och namnet användaren */
export async function createUser(uid, name) {
  var userCatchCode = await makeid(4);
  var ValidateCatch = functions.httpsCallable("CreateUser");
  var returnValue = await ValidateCatch({
    userID: uid,
    userName: name,
    userCatchCode: userCatchCode,
  });
  if (!returnValue) {
    // Ta inte bort denna console log.
    console.log("Error when creating user. See cloud logs for more details");
    return {
      error: true
    }
  } else if (returnValue) {
    return {
      error: false
    }
  }
}
/*Tar bort en användare i databasen utifrån uid*/
export async function removeUser(uid) {
  const status = db.collection("users")
    .doc(uid)
    .delete()
    .then(function () {
      return {
        error: false
      }
    })
    .catch(function (error) {
      return {
        error: true
      }
    });
    return status;
}

export async function getUser(uid) {
  if (!uid) {
    return null;
  }
  try {
    const userDocument = await db.collection("users").doc(uid).get();
    if (userDocument.exists) {
      console.log("exists");
      return {
        uid,
        ...userDocument.data(),
      };
    } else {
      console.log("does not exists");
      return false;
    }
  } catch (error) {
    console.error("Error fetching user", error);
  }
}

// Funktion som tar in en userId och returnerar namnet på den userns target
export async function getTarget(userId) {
  const user = await getUser(userId);
  const target = await getUser(user.target);
  if (!target) {
    throw new Error("No target found");
  }
  return target.name;
}
/* Uppdatera en användare, använd key + value för att ändra ett värde. Ex isAlive och true*/
export function updateUser(collection, id, key, value) {
  db.collection(collection)
    .doc(id)
    .update({
      [key]: value,
    });
}

export async function userHasCatchCode(id) {
  var userHasCatchCode = functions.httpsCallable("UserHasCatchCode");
  var returnValue = await userHasCatchCode({
    userId: id,
  });
  return returnValue.data.returnBool;
}

export async function userExists(id) {
  const userExists = functions.httpsCallable("UserExists");
  const returnValue = await userExists({
    userId: id,
  });
  return returnValue.data.returnBool;
}
