import React, { Component } from 'react';
import Papa from 'papaparse';

import {
    createUser
  } from "../../helpers/users";

class FileReader extends Component {
    constructor() {
      super();
      this.state = {
        csvfile: undefined
      };
      this.updateData = this.updateData.bind(this);
    }
  
    handleChange = event => {
      this.setState({
        csvfile: event.target.files[0]
      });
    };
  
    importCSV = () => {
      const { csvfile } = this.state;
      Papa.parse(csvfile, {
        complete: this.updateData,
        header: true
      });
    };
  


    updateData(result) {
      var data = result.data;
      data.forEach(element => createUser(element.Id, element.Name));
    }
  
    render() {
      return (
        <div className="Filereader">
          <h5>Importera CSV</h5>
          <p>
            Användare kan läggas till i bulk från en .csv-fil. <br/>
            Om ett Id hos en användare som laddas upp redan finns i databasen kommer denne att skrivas över med användaren från filen. <br/>
            Exempel på hur .csv-filen ska vara strukturerad: <br/> <br/>
            Id,Name <br/>
            841, Jens Bleckert <br/>
            747, Hannes Samskog <br />
            672, Niklas Appelquist
          </p>
          <h6>Välj fil</h6>
          <input
            className="csv-input"
            type="file"
            ref={input => {
              this.filesInput = input;
            }}
            name="file"
            accept=".csv"
            placeholder={null}
            onChange={this.handleChange}
          />
          <p />
          <button className="user-table-button" onClick={this.importCSV}> Ladda upp!</button>
        </div>
      );
    }
  }
  
  export default FileReader;