import React from 'react';
import { UserContext } from "../../providers/UserProvider.jsx";
import { AnimatePresence, motion } from 'framer-motion';
import { NavLink } from "react-router-dom";
import MenuToggle from "./MenuToggle";
import "./MobileNavLinks.css";

const MobileNavLinks = ({ handleSignOut, isMobileMenuOpen, toggle }) => {

    return (
        <div className="MobileNavLinks-container">
            <MenuToggle isOpen={isMobileMenuOpen} toggle={toggle} />
            <AnimatePresence>
                {isMobileMenuOpen && (
                    <motion.div
                        animate={{ height: "100vh" }}
                        initial={{ height: "0vh", opacity: 1}}
                        exit={{ height: "0vh", opacity: 0}}
                        className="MobileNavLinks-wrapper">
                        <NavLink
                            className="MobileNavLinks-item"
                            to="/leaderboard"
                            onClick={toggle}
                        >
                            Leaderboard
                        </NavLink>
                        <NavLink
                            className="MobileNavLinks-item"
                            to="/Rules"
                            onClick={toggle}
                        >
                            Regler
                        </NavLink>
                        <NavLink
                            className="MobileNavLinks-item"
                            to="/news"
                            onClick={toggle}
                        >
                            Nyheter
                        </NavLink>
                        <UserContext.Consumer>
                            {(user) => user.isAdmin && (
                                <NavLink
                                    className="MobileNavLinks-item"
                                    to="/admin"
                                    onClick={toggle}
                                >
                                    Admin
                                </NavLink>
                            )}
                        </UserContext.Consumer>
                        <button className="MobileNavbar-logout-button" onClick={handleSignOut}>Logga ut</button>
                    </motion.div>
                )}
            </AnimatePresence>

        </div>
    );
}

export default MobileNavLinks;