import React, { Component } from "react";
import { createNews } from "../../helpers/news";

class NewsManager extends Component {
  constructor(props) {
    super(props);
    this.state = {
      newsTitle: "",
      newsText: "",
      newsStartDate: "",
      newsEndDate: "",
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleAddNews = this.handleAddNews.bind(this);
  }

  handleChange(e) {
    this.setState({
      [e.target.name]: e.target.value,
    });
  }

  async handleAddNews(e) {
    e.preventDefault();
    var startDate = this.createDate(this.state.newsStartDate);
    var endDate = this.createDate(this.state.newsEndDate);
    const status = await createNews(this.state.newsTitle, this.state.newsText, startDate, endDate);
    console.log(status);
    if (status.error) {
      this.props.handleError("Något gick fel...");
    }
    else if(!status.error) {
      this.props.handleSuccess("Nyhet skapad!");
    }
  }

  createDate(dateString) {
    //Fixa ett sätt att hantera errors här
    dateString = dateString.split("-");
    var date = new Date();
    //-1 på månad för att månadsräknaren börjar på 0
    date.setFullYear(dateString[0], dateString[1] - 1, dateString[2]);
    return date;
  }

  render() {
    return (
      <div className="newsmanager">
        <h2>Lägg till nyhet</h2>
        <form className="addNews" onSubmit={this.handleAddNews}>
          <input
            required
            className="addNews-input"
            type="Title"
            placeholder="Rubrik..."
            name="newsTitle"
            maxLength="28"
            value={this.state.newsTitle}
            onChange={this.handleChange}
          />
          <textarea
            required
            className="addNews-input-text"
            rows="5"
            placeholder="Innehåll..."
            name="newsText"
            value={this.state.newsText}
            onChange={this.handleChange}
          />
          <div className = "startDate">
          <label>Startdatum</label>
          <input
            required
            className="addNews-input"
            type="date"
            placeholder="Startdatum"
            name="newsStartDate"
            value={this.state.newsStartDate}
            onChange={this.handleChange}
          />
        </div>
        <div className = "endDate">
          <label>Slutdatum</label>
          <input
            required
            className="addNews-input"
            type="date"
            placeholder="Slutdatum"
            name="newsEndDate"
            value={this.state.newsEndDate}
            onChange={this.handleChange}
          />
        </div>
          <br></br>
          <button className="user-table-button" type="submit">
            Lägg till nyhet
          </button>
        </form>
      </div>
    );
  }
}

export default NewsManager;
